import React, { useEffect, useState } from "react";
import "./EventModel.css";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from "moment";
import Modal from 'react-modal';
import TripDataService from "../../services/TripData";
import MainNavbar from "../../Components/Navbar/MainNavbar";
import FotterAll from "../../Components/Footer/FotterAll";
import { Link } from "react-router-dom"


const EventModalMobile = () => {

  // const [calenderEvents, setcalenderEvents] = useState([]);
  const [availableDates, setAvailableDates] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState([]);

  const vehicleAvailabilityArr = [];
  const [vehicleAvailabilityArr1, setVehicleAvailabilityArr1] = useState([]);
  const [petObject, setPetObject] = useState([]);
  const [loading, setLoading] = useState(true);
  const [errorMsg, setErrorMsg] = useState('Fetching Trips...');
  const [tripsData, setTripsData] = useState([]);
  const [trips, setTrips] = useState([]);
  const [actualtrips, setActualTrips] = useState([]);


  // console.log(availableDates,"availableDates"); 
  const dateData = [];

  
  useEffect(() => {
    var storageData = JSON.parse(localStorage.getItem("scheduleSelected"));
    setAvailableDates(storageData.availableDates);
    setSelectedEvent(storageData.selectedEvent);

    TripDataService.fetchTripsInfo([])
      .then((response) => {
        if (response.status !== "error") {
          setTripsData(response.tripData);
          //setError("");
        } else {
          //setError(response.message);
        }
      })
      .catch((err) => {
        //setError(err.message);
        //setLoading(false);
      });
    var rawData = JSON.stringify({
      year: moment().format("Y"),
      //sailing_date: sailing_date,
      sailing_date: moment(storageData.selectedEvent, "MM/D/YYYY").format("MM/D/YYYY"),
      live_data: true,
    });
    getSelectedDateTripsData(rawData);
      
  }, []);
  const getSelectedDateTripsData = function(rawData) {
    TripDataService.fetchTrips(rawData)
      .then((response) => {
        setTrips([]);
        setActualTrips([]);
        if (response.status !== "error") {
          setAvailableDates(response.sailings);
          var actualTrips = response.sailings;
          const tripResult = actualTrips.filter(d=>moment.utc(moment(d.sailing_date+" "+d.sailing_time, "MM/D/YYYY HH:mm")).isAfter(moment(new Date())));
          setTrips(tripResult);
          setActualTrips(tripResult);
          
          //const sailing_date = moment(storageData.selectedEvent, "MM/D/YYYY").format("MM/D/YYYY");
          
          const sailing_date = JSON.parse(rawData).sailing_date;
          const VehicleData = JSON.stringify({
            cruise_id: 54,
            sailing_date,
            sailing_type: "1_way",
            sailing_way: "onward",
          });
          var petobj = [];
          TripDataService.fetchMultipleTripVehicles(VehicleData)
          .then((onwardResp) => {
            if (onwardResp.status !== "error") {
              onwardResp.excursion_details.map((return_response) => {
                const sailing_time = moment(return_response.sailing_time, [
                  "HH:mm:ss",
                ]).format("HH:mm:ss"); //return_response.sailing_time;
    
                var addons_master_data = return_response.onshore_excursion_details;
    
                var addons_master_data_grouped = groupBy1(
                  addons_master_data,
                  "excursion_type_name"
                );
                //console.log("addons_master_data_grouped : ", addons_master_data_grouped);
    
                var addons_master_data_group = {};
    
                Object.keys(addons_master_data_grouped).forEach((sup_type) => {
                  var addon_availabilities = {};
                  let supQuantity = 0;
                  if (sup_type === "Car" || sup_type === "Bus") {
                    const minQuantity = Math.max.apply(
                      null,
                      addons_master_data_grouped[sup_type].map(function (item) {
                        return item.available_quantity;
                      })
                    );
                    supQuantity = minQuantity;
    
                    addons_master_data_group.car = supQuantity;
                  } else if (sup_type === "Two-wheeler") {
                    addons_master_data_grouped[sup_type].forEach((sup_val) => {
                      if (sup_val.excursion_name === "Bicycle") {
                        supQuantity = parseInt(sup_val.available_quantity);
                        addons_master_data_group.cycle = supQuantity;
                      } else if (sup_val.excursion_name === "Motorcycle/Scooter") {
                        supQuantity = parseInt(sup_val.available_quantity);
                        addons_master_data_group.bike = supQuantity;
                      }
                    });
                  } else if (sup_type === "Pet") {
                    var petinfo = {};
    
                    petinfo.sailing_time = sailing_time;
                    petinfo.info = addons_master_data_grouped[sup_type][0];
                    petobj.push(petinfo);
    
                    setPetObject(petobj);
                  } else {
                    addons_master_data_grouped[sup_type].forEach((sup_val) => {
                      supQuantity += parseInt(sup_val.available_quantity);
                    });
                    addons_master_data_group[sup_type.toLowerCase()] = supQuantity;
                  }
                });
    
                //console.log("addons_master_data_group : ", addons_master_data_group);
    
                if (
                  addons_master_data_group.length > 0 ||
                  addons_master_data_group != ""
                ) {
                  //console.log(selvehicleData);
                  var caraddonErrText = "";
                  var bikeaddonErrText = "";
                  var cycleaddonErrText = "";
                  const vehiclesTemp = {
                    car: 1,
                    cycle: 1,
                    bike: 1,
                  };
                  
                  Object.keys(vehiclesTemp).forEach((vahicle_val) => {
                    const selectedQuantity = parseInt(vehiclesTemp[vahicle_val],10);
                    const availableQuantity = parseInt(addons_master_data_group[vahicle_val],10);
                    //console.log("VVVVV : ", return_response.sailing_time, availableQuantity, selectedQuantity, vahicle_val);
                    if (vahicle_val === "car") {
                      //console.log(availableQuantity, "car");
                      if (availableQuantity > 0) {
                        caraddonErrText = "Available";
                      } else {
                        if (selectedQuantity > 0) {
                          if (selectedQuantity > availableQuantity) {
                            caraddonErrText = "Sold Out";
                          } else {
                            caraddonErrText = "Filling Fast";
                          }
                        } else {
                          caraddonErrText = "Available";
                        }
                      }
                    }
    
                    if (vahicle_val === "bike") {
                      if (availableQuantity > 10) {
                        bikeaddonErrText = "Available";
                      } else {
                        if (selectedQuantity > 0) {
                          if (selectedQuantity > availableQuantity) {
                            bikeaddonErrText = "Sold Out";
                          } else {
                            bikeaddonErrText = "Filling Fast";
                          }
                        } else {
                          bikeaddonErrText = "Available";
                        }
                      }
                    }
    
                    if (vahicle_val === "cycle") {
                      //console.log(availableQuantity, selectedQuantity, "cycle");
                      if (availableQuantity > 10) {
                        cycleaddonErrText = "Available";
                      } else {
                        if (selectedQuantity > 0) {
                          if (selectedQuantity > availableQuantity) {
                            cycleaddonErrText = "Sold Out";
                          } else {
                            cycleaddonErrText = "Filling Fast";
                          }
                        } else {
                          cycleaddonErrText = "Available";
                        }
                      }
                    }
                  });
                  const vehicleAvailability = {};
    
                  vehicleAvailability.sailing_date = sailing_date;
                  vehicleAvailability.sailing_time = return_response.sailing_time + ":00";
                  const datetime = moment(sailing_date, "MM/DD/YYYY").format("MM/DD/YYYY") +" " + return_response.sailing_time +":00";
                  vehicleAvailability.accesstime = datetime;
    
                  if ( caraddonErrText == "undefined" || caraddonErrText == undefined) {
                    vehicleAvailability.carAvailabilityText = "Available";
                  } else {
                    if (caraddonErrText == "Sold Out") {
                      vehicleAvailability.cardisabled = true;
                    } else {
                      vehicleAvailability.cardisabled = false;
                    }
                    vehicleAvailability.carAvailabilityText = caraddonErrText;
                  }
                  //vehicleAvailability.carAvailabilityText = "Filling Fast";
                  if (
                    bikeaddonErrText == "undefined" ||
                    bikeaddonErrText == undefined
                  ) {
                    vehicleAvailability.bikeAvailabilityText = "Available";
                  } else {
                    if (bikeaddonErrText == "Sold Out") {
                      vehicleAvailability.bikedisabled = true;
                    } else {
                      vehicleAvailability.bikedisabled = false;
                    }
                    vehicleAvailability.bikeAvailabilityText = bikeaddonErrText;
                  }
    
                  if (
                    cycleaddonErrText == "undefined" ||
                    cycleaddonErrText == undefined
                  ) {
                    vehicleAvailability.cycleAvailabilityText = "Available";
                  } else {
                    if (cycleaddonErrText == "Sold Out") {
                      vehicleAvailability.cycledisabled = true;
                    } else {
                      vehicleAvailability.cycledisabled = false;
                    }
                    vehicleAvailability.cycleAvailabilityText = cycleaddonErrText;
                  }
                  vehicleAvailabilityArr.push(vehicleAvailability);
                  setVehicleAvailabilityArr1(vehicleAvailabilityArr);
                  //console.log("vehicleAvailabilityArr", vehicleAvailabilityArr);
                } else {
                  var vehicleAvailability = {};
                  vehicleAvailability.sailing_date = sailing_date;
                  vehicleAvailability.sailing_time = sailing_time;
                  const datetime = moment(sailing_date).format("MM/DD/YYYY") + " " + sailing_time;
                  vehicleAvailability.accesstime = datetime;
                  vehicleAvailability.carAvailabilityText = "Available";
                  vehicleAvailability.bikeAvailabilityText = "Available";
                  vehicleAvailability.cycleAvailabilityText = "Available";
    
                  vehicleAvailabilityArr.push(this.vehicleAvailability);
                  setVehicleAvailabilityArr1(vehicleAvailabilityArr);
                }
                setLoading(false);
              });
            }
            else {
              setErrorMsg(onwardResp.message);
              // console.log("Show error alert with : ", onwardResp.message);
              //$(".errorMsg").html(onwardResp.message);
            }
          });
        }
        else{
          console.log("Error");
        }
      })
      .catch((err) => {
        /* setError(err.message);
        setLoading(false); */
      });
  };
  availableDates.map(availableDate => {
    // console.log(availableDate);
    if (dateData.indexOf(availableDate.sailing_date) === -1) {
      dateData.push(availableDate)
    }
  });
  //  console.log(selectedEvent);
  const formatDate = moment(selectedEvent, "MM/D/YYYY").format("D MMMM YYYY");
  const groupBy1 = function (xs, key) {
    return xs.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };

  let items = [...trips]
    .sort(function (a, b) {
      return a.cruise_id - b.cruise_id;
    })
    .map((item) => {
      item.availablity.sort(
        (a, b) => a.single_room_price - b.single_room_price
      );
      return item;
    });

  const formatDay = new Intl.DateTimeFormat("en-US", {
    weekday: "long",
  }).format(selectedEvent.date);

  // selct tag all tags will tehrer


// for calender open

const [selectedDateNew, setSelectedDateNew] = useState(null);
const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);

const handleDateChangeNew = (date) => {
  setSelectedOptionVessel(null)
  setSelectedOptionRoute(null)
  setSelectedOptionSort(null)
  setSelectedEvent(date);

  setSelectedDateNew(date);
  setIsDatePickerOpen(false); 

  var rawData = JSON.stringify({
    year: moment().format("Y"),
    //sailing_date: sailing_date,
    // sailing_date: moment(selectedEvent.date.$d).format("MM/D/YYYY"),
    //sailing_date: moment(date, "MM/D/YYYY").format("MM/D/YYYY"),
    sailing_date: moment(date, "MM/D/YYYY").format("MM/D/YYYY"),
    live_data: true,
  });
  
  getSelectedDateTripsData(rawData);
};

const toggleDatePicker = () => {
  setIsDatePickerOpen(!isDatePickerOpen);
};

const containerStyle = {
  position: 'relative',
  height: isDatePickerOpen ? 'auto' : '40px', 
};


//FOR VESSE L SORTIONG
// for mobile new dropdown seclct tags
const Vessel = [
  {vesselName : 'All', vesselCode : ""},
  {vesselName : 'M2M - 1', vesselCode : "M2M - 1"},
  // {vesselName : 'Princess', vesselCode : "Princess"}
];
const [isOpenVessel, setIsOpenVessel] = useState(false);
const [selectedOptionVessel, setSelectedOptionVessel] = useState(Vessel.find(v => v.vesselCode === "M2M - 1") || null);


const toggleDropdownVessel = () => {
  setIsOpenVessel(!isOpenVessel);
};

const handleOptionClickVessel = (option) => {
  if (option.vesselName === "All") {
    setTrips(actualtrips);
  } else {
    const tripResult = actualtrips.filter(d => d.cruise_name === option.vesselCode);
    setTrips(tripResult);
  }
  setSelectedOptionVessel(option); 
  setIsOpenVessel(false); 
};

// FOR ROUTES SORTING

const [isOpenRoute, setIsOpenRoute] = useState(false);
const [selectedOptionRoute, setSelectedOptionRoute] = useState(null);
const Triproutes = [{routeName : 'All', routeCode : ""}, {routeName : 'Mumbai - Mandwa', routeCode : "MUM"}, {routeName : 'Mandwa - Mumbai', routeCode : "MWA"}];

const toggleDropdownRoute = () => {
  setIsOpenRoute(!isOpenRoute);
};

const handleOptionClickRoute = (option) => {
  if(option.routeName === "All"){
    setTrips(actualtrips);
  }
  else{
    var tripResult = actualtrips.filter(d=>d.start_port === option.routeCode);
    setTrips(tripResult);
  }
  
  setSelectedOptionRoute(option.routeName);
  setIsOpenRoute(false); // Close the dropdown after selection
};

// SORT BY CODE

const SortBy = [
  { sortName: 'All', sortCode: "" },
  { sortName: 'Price - High', sortCode: "high" },
  { sortName: 'Price - Low', sortCode: "low" }
];
const [isOpenSort, setIsOpenSort] = useState(false);
const [selectedOptionSort, setSelectedOptionSort] = useState(SortBy[0]); // Default to 'All'

const toggleDropdownSort = () => {
  setIsOpenSort(prevState => !prevState); 
};
const handleOptionClickSort = (option) => {
  if (option.sortCode === "") {
    setTrips(actualtrips); 
  } else {
    let tripResult = [...actualtrips];
    
    if (option.sortCode === "low") {
      tripResult = tripResult.sort((a, b) => {
        const aPrice = parseFloat(a.availablity[0]?.single_room_price || 0);
        const bPrice = parseFloat(b.availablity[0]?.single_room_price || 0);
        return aPrice - bPrice;
      });
    } else if (option.sortCode === "high") {
      tripResult = tripResult.sort((a, b) => {
        const aPrice = parseFloat(a.availablity[1]?.single_room_price || 0);
        const bPrice = parseFloat(b.availablity[1]?.single_room_price || 0);
        return bPrice - aPrice;
      });
    }

    setTrips(tripResult);
  }
  setSelectedOptionSort(option);
  setIsOpenSort(false); 
};


useEffect(() => {
  setTrips(actualtrips); 
}, [actualtrips]);


const [isEventModelExpand,setIsEventModelExpand] = useState(-1);

const handleExpandRow = (index)=>{
  // setIsEventModelExpand(!isEventModelExpand === index)
  setIsEventModelExpand(index === isEventModelExpand ? -1 : index); 
}

const isExpanded = (index) => {
  return isEventModelExpand === index;
};

const handleSubmitReverse = () => {
  window.location = "/schedule";  
};

const [tripType, setTripType] = useState("onward");

// FOR GO TO THE DEPARTURE PAGE WITH BY DEFULT (1) PASSNAGER
function SearchTripsScheduleMobile(selectedFromAbbreviation, selectedToAbbreviation) {
  // const passengersData = { Adultcount: 1,ChildCnt:0,PetCnt:0 };
  // const vehicleData ={ CarCnt: 0,ScooterCnt:0, CycleCnt:0,BusCnt:0 };
  const passengersData = { Adultcount: 1,Childcount:0,Petcount:0 };
  const vehicleData ={ carCount: 0,scooterCount:0, cycleCount:0,busCount:0 };
  const selectedDateNew1 = formatDate ? moment(formatDate).format("MM/D/YYYY") : "";
  // const abbreviationFormTo = { Mumbai: "MUM", Mandwa: "MWA",};
  // const selectedFromAbbreviation =abbreviationFormTo["Mumbai"];
  // const selectedToAbbreviation = abbreviationFormTo["Mandwa"];
  var allowNext = true;
  const onwardJourney = {
    selectedDateNew1,
    selectedFromAbbreviation,
    selectedToAbbreviation,
    passengersData,
    vehicleData,
  };
  var formDataToStore;
  if (tripType === "onward") {
    formDataToStore = {
      triptype: tripType,
      onwardJourney,
    };
  }
  localStorage.setItem("formData", JSON.stringify(formDataToStore));
  if(allowNext)
    window.location = "/onward-departure"; 
}


  return (
    <div className="modal-background-mobile">
      <div className="navbarContainer-sticky Main-navbar-border">
        <MainNavbar />
      </div>
      <div className="MobileSchedule_Continer">
        <div className="ReverseFlow_ScheduleMobile">
             <h1 onClick={handleSubmitReverse}>Schedule</h1>
             <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 12.705 21.229">
                <path id="Alt_Arrow_Down" data-name="Alt Arrow Down" d="M21.988,9l-8.494,9.084L5,9" transform="translate(-6.88 24.109) rotate(-90)" fill="none" stroke="#767c8d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3"/>
             </svg>
             <h3>Schedule List</h3>
         </div>
         <div className="EventModel_Header" style={containerStyle}>
              <div className="EventModel_Header_Day_Date" onClick={toggleDatePicker}>
                <div className="CurrentDateShow">
                  {/* <h2>{selectedDateNew ? selectedDateNew.moment(selectedEvent).format("D MMMM YYYY") : 'Add Date'}</h2> */}
                  <h2>{formatDate}</h2>
                  <svg className="custom-svg" xmlns="http://www.w3.org/2000/svg" width="16.82" height="8.41" viewBox="0 0 16.82 8.41"
                    style={{ transform: isDatePickerOpen ? 'rotate(180deg)' : 'rotate(0deg)' }}>
                    <path id="Alt_Arrow_Down"data-name="Alt Arrow Down"d="M19,9l-7,6L5,9"transform="translate(-3.59 -7.59)"fill="none"stroke="#000"strokeLinecap="round"strokeLinejoin="round"strokeWidth="2"/>
                  </svg>
                </div>
              </div>
              {isDatePickerOpen && (
                <div style={{ position: 'absolute', top: '100%', left: 0, zIndex: 999,backgroundColor:'white'}}>
                  <DatePicker selected={selectedDateNew} onChange={handleDateChangeNew} minDate={new Date()} inline/>
                </div>
              )}
         </div>

           {/* ALL Slect Tags in model for Mobile */}
           <div className="CleanderMobile_dropdown">
        <div className='CalenderSlectTags'>
          <div className='VesselAndRoutCommanDiv'>
            <h1>Select Vessel</h1>
            <div className='VesselDiv_Tag'>
            {/* <p onClick={toggleDropdownVessel}> {selectedOptionVessel ? selectedOptionVessel : 'Select Vessel'} </p>*/}
            <p onClick={toggleDropdownVessel}> {selectedOptionVessel ? selectedOptionVessel.vesselName : 'Select Vessel'} </p>
              <svg xmlns="http://www.w3.org/2000/svg" width="12.828" height="7.414" viewBox="0 0 12.828 7.414">
                <path id="Alt_Arrow_Down" data-name="Alt Arrow Down" d="M15,9l-5,5L5,9" transform="translate(-3.586 -7.586)" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
              </svg>
            </div>
          </div>
          <div className='VesselAndRoutCommanDiv'>
            <h1>Select Route</h1>
            <div className='VesselDiv_Tag'>
            <p onClick={toggleDropdownRoute}> {selectedOptionRoute ? selectedOptionRoute : 'Select Route'}</p>
              <svg xmlns="http://www.w3.org/2000/svg" width="12.828" height="7.414" viewBox="0 0 12.828 7.414">
                <path id="Alt_Arrow_Down" data-name="Alt Arrow Down" d="M15,9l-5,5L5,9" transform="translate(-3.586 -7.586)" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
              </svg>
            </div>
          </div>
        
        </div>
        <div className='CaelderSlectTags_Sort'>
        <div className='SortBy'>
            <img onClick={toggleDropdownSort} src="/assets/img/Calender/SortBy.png" alt="Sort-by" />
          </div>
        </div>
            <Modal isOpen={isOpenVessel} onRequestClose={() => setIsOpenVessel(false)} contentLabel="Vessel Options Modal" className="ReactModel_Calender_SlectTags"
            style={{content: { position: 'absolute', bottom: 0, left: 0, right: 0, top: 'auto', maxHeight: 'auto', overflow: 'auto',},}}>
            <div className="dropdown-options-calender">
               <div className="close_button" onClick={()=> setIsOpenVessel(false)} >
                   <p>Select Vessel</p>
                   <img src="assets/img/Widget/Arrow_Up.png" alt="Close" />
               </div>
              {Vessel.map((option, index) => (
                <div className='Option_SlectTags' key={index} onClick={() => handleOptionClickVessel(option)}>
                  <p>{option.vesselName}</p>
                </div>
              ))}
            </div>
          </Modal>
        
          <Modal isOpen={isOpenRoute} onRequestClose={() => setIsOpenRoute(false)} contentLabel="Route Options Modal" className="ReactModel_Calender_SlectTags"
            style={{
              content: { position: 'absolute', bottom: 0, left: 0, right: 0, top: 'auto', maxHeight: 'auto', overflow: 'auto',},
            }}>
            <div className="dropdown-options-calender">
              <div className="close_button" onClick={()=> setIsOpenRoute(false)} >
                   <p>Select Route</p>
                   <img src="assets/img/Widget/Arrow_Up.png" alt="Close" />
               </div>
              {Triproutes.map((option, index) => (
                <div className='Option_SlectTags' key={index} onClick={() => handleOptionClickRoute(option)}>
                  <p>{option.routeName}</p>
                </div>
              ))}
            </div>
          </Modal>
          
          <Modal isOpen={isOpenSort} onRequestClose={() => setIsOpenSort(false)} contentLabel="Route Options Modal" className="ReactModel_Calender_SlectTags"
            style={{
              content: { position: 'absolute', bottom: 0, left: 0, right: 0, top: 'auto',maxHeight: 'auto',overflow: 'auto',},
            }}>
            <div className="dropdown-options-calender">
              <div className="close_button" onClick={()=> setIsOpenSort(false)} >
                   <p>Sort By</p>
                   <img src="assets/img/Widget/Arrow_Up.png" alt="Close" />
               </div>
              {SortBy.map((option, index) => (
                <div className='Option_SlectTags' key={index} onClick={() => handleOptionClickSort(option)}>
                  <p>{option.sortName}</p>
                </div>
              ))}
            </div>
          </Modal>
          </div>

        <div className="modal-content-mobile" onClick={(e) => e.stopPropagation()}>
          <div className="Availability_Table_mob">
              <div className="table-container">
                <table>
                  <thead>
                    <tr>
                      <th>Vessel</th>
                      <th>Route</th>
                      <th>Depart Time</th>
                      <th>Arrive Time</th>
                      <th>Booking Availability</th>
                    </tr>
                  </thead>
                  <tbody>
                    
                    {trips.length > 0 ?(
                      <>
                      {trips.map((row, index) =>
                        <React.Fragment key={index}>
                        <tr>
                          <td>{row.cruise_name}</td>
                          <td>{row.sailing_route}</td>
                          <td>{moment(row.sailing_time, ["HH:mm:ss",]).format("LT")}</td>
                          <td>{moment(row.sailing_date +" " +row.sailing_time, "DD/MMM/YYYY HH:mm").add(1, "hours").format("hh:mm A")}</td>
                         <td>
                            <div className="CheckAvaiblity_TableTd"  onClick={() => handleExpandRow(index)}>
                              <p>Check Availability</p>
                              <img  className={isExpanded(index)?"arrow rotated" :"arrow"} src="/assets/img/Calender/ArrowBlue.png" alt="arrow"/>
                          </div>
                         </td>
                        </tr>
                        {isEventModelExpand === index && (
                          <tr className="expanded-row">
                            <td colSpan="5">
                              <div className="expanded_content_TableEventModal">
                                <div className="ShowExpandedRow_Tags">
                                    {row.availablity.sort().map((availablity, index1) => (
                                        <React.Fragment key={index1}> 
                                        <div className={`CommanNameTags_ExpandRow border-right-expandrow`} onClick={() => SearchTripsScheduleMobile(row.start_port, row.end_port)}>
                                          <h1>{tripsData[availablity.category_id].category_name}</h1>
                                            <div className={`${availablity.available_room > 0  && !( moment(row.sailing_date, "MM/DD/YYYY").isBetween( tripsData[availablity.category_id].start_date, tripsData[availablity.category_id].end_date, undefined, "[]" ) && tripsData[availablity.category_id].status == 0 ) ? ( (availablity.available_room / availablity.total_room) * 100 >= tripsData[availablity.category_id].feeling_threshold ? "availability" : (availablity.available_room / availablity.total_room) * 100 < tripsData[availablity.category_id].feeling_threshold ? "fillingfast" : "" ): availablity.available_room < 1 && !( moment(row.sailing_date).isBetween(tripsData[availablity.category_id].start_date,tripsData[availablity.category_id].end_date,undefined,"[]") && tripsData[availablity.category_id].status == 0) ? "sold-out" : "not-available"}`}>
                                              <span className="dot"></span>
                                              <span className="available-text">{availablity.available_room > 0  && !( moment(row.sailing_date, "MM/DD/YYYY").isBetween( tripsData[availablity.category_id].start_date, tripsData[availablity.category_id].end_date, undefined, "[]" ) && tripsData[availablity.category_id].status == 0 ) ?  ( (availablity.available_room / availablity.total_room) * 100 >= tripsData[availablity.category_id].feeling_threshold ? "Available" : (availablity.available_room / availablity.total_room) * 100 < tripsData[availablity.category_id].feeling_threshold ? "filling fast" :  "" ): availablity.available_room < 1 && !( moment(row.sailing_date).isBetween(tripsData[availablity.category_id].start_date,tripsData[availablity.category_id].end_date,undefined,"[]") && tripsData[availablity.category_id].status == 0) ? "sold out" : "Not Available"}</span>
                                            </div>
                                        </div>
                                                                    
                                        {index1 < (row.availablity.length - 1) ? (<div className="divider_VerticalLine"></div>):null}
                                        </React.Fragment>
          
                                      ))}
                                  </div>

                                  <div className="ShowExpandedRow_Avability_Status">
                                      <div className="ShowExpandedRow_Avability_Status_div">
                                        <img src="/assets/img/FlowImg/car.png" alt="car"/>
                                        <div className="CommanNameTags_ExpandRow">
                                            {vehicleAvailabilityArr1.map((addon, index2) =>
                                              addon.accesstime == row.sailing_date + " " + row.sailing_time ? (
                                              <div key={index2} className={`${addon.carAvailabilityText === "Available" ? "availability" : addon.carAvailabilityText === "Filling Fast" ? "fillingfast" : addon.carAvailabilityText === "Sold Out" ? "sold-out" : ""}`}>
                                                <span className="dot"></span>
                                                <span className="available-text" key={index2}>{addon.carAvailabilityText}</span>
                                              </div>
                                            ) : null
                                            )}
                                        </div>                                 
                                       </div>
                                      <div className="ShowExpandedRow_Avability_Status_div">
                                      <img src="/assets/img/Widget/Scooter2.0.png" alt="scooter"/>
                                        <div className="CommanNameTags_ExpandRow">
                                          {vehicleAvailabilityArr1.map((addon, index2) =>
                                            addon.accesstime == row.sailing_date + " " + row.sailing_time ? (
                                              <div key={index2} className={`${addon.bikeAvailabilityText === "Available" ? "availability" : addon.bikeAvailabilityText === "Filling Fast" ? "fillingfast" : addon.bikeAvailabilityText === "Sold Out" ? "sold-out" : ""}`}>
                                                <span className="dot"></span>
                                                <span className="available-text" key={index2}>{addon.bikeAvailabilityText}</span>
                                              </div>
                                            ) : null
                                          )}
                                        </div>
                                      </div>                   
                                  </div>

                                  <div className="row-button-schedule">
                                  <Link to={`/?sailing_date=${encodeURIComponent(row.sailing_date)}`}>
                                  <button className="row-button-schedule-BookNow"  type="submit">Book Now</button>
                                    </Link>
                                  </div>
                              </div>
                            </td>
                          </tr>
                        )}            
                        </React.Fragment>              
                        )}
                        </>
                    ):(
                        <tr>
                          <td colSpan={5}>No Trips Available.</td>
                        </tr>
                    )}
                    
                  </tbody>
             </table>
              </div>
          </div>
        </div>
      </div>
        <FotterAll/>
    </div>
  );
};
export default EventModalMobile;
