import React, { useRef, useState,useEffect } from 'react';
import "./Top_Attration.css";
import { useNavigate } from 'react-router-dom';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import StaticDataService from "../../services/staticData";
import { M2MApiUrl } from "../../services/staticData";

const Top_Attration = () => {
    const sliderRef = useRef();
    const [currentPage, setCurrentPage] = useState(1);
    const [data, setData] = useState({ categories: [] });
    const [activeTab, setActiveTab] = useState(0);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);


    useEffect(() => {
        StaticDataService.exploreService()
            .then((response) => {
                if (response && response.success && Array.isArray(response.categories)) {
                    setData(response); 
                } else {
                    console.error("Error in response:", response);
                }
            })
            .catch((err) => {
                console.error("API Error:", err);
            })
            .finally(() => {
                setLoading(false); 
              });
    }, []);


    // const tabTitles = ["Restaurants", "Hotels", "Activities", "Sightseeing"];
    const tabTitles = data.categories.map(category => category.category_name || '');

    //     console.log("tabTitles :",tabTitles)

    // const restaurants = [
    //     {
    //         title: "Alto Vino Restaurant & Bar",
    //         image: "/assets/img/explore-page/explore-comman-content-1.png",
    //         description: "European, Italian, Chinese",
    //         price: {
    //             amount: "₹1500",
    //             text: "for 2 people"
    //         },
    //         rating: "4.1"
    //     },
    // ];

    // const hotels = [
    //     {
    //         title: "Luxury Stay Hotel",
    //         image: "/assets/img/explore-page/explore-slider-1.png",
    //         description: "5-star, Luxury, Spa",
    //         rating: "4.7"
    //     },

    // ];

    // const activities = [
    //     {
    //         title: "Adventure Park",
    //         image: "/assets/img/explore-page/explore-slider-3.png",
    //         price: {
    //             amount: "₹1500",
    //             text: "per person"
    //         },
    //     },
    // ];

    // const sightseeing = [
    //     {
    //         title: "Famous Landmark",
    //         image: "/assets/img/explore-page/explore-comman-content-5.png",
    //         rating: "4.6"
    //     },
    // ];


    // const contentArray = [restaurants, hotels, activities, sightseeing];
    const contentArray = data.categories.map(category => category.posts || []);
    
    const handleItemClick = (item) => {
        // console.log("Clicked item:", item);
        
        if (item && item.title) {
            const slug = item.slug;
            
            const categoryName = tabTitles[activeTab];
           
            var category_name = categoryName.toLowerCase();
console.log("category_name",category_name)
            if (categoryName) {
                navigate(`/explore/${category_name}/${slug}`);
            } else {
                console.error("No route found for category:", categoryName);
            }
        } else {
            console.error("Item is undefined or does not have a title:", item);
        }
    };
    
    
    
    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        // afterChange: (currentSlide) => {
        //     setCurrentPage(currentSlide + 1);
        //   },
        afterChange: (currentSlide) => {
            setCurrentPage(currentSlide + 1);
        },
        // prevArrow: <button className="prev-btn">Prev</button>,
        // nextArrow: <button className="next-btn">Next</button>
      };
    //   const totalPage = contentArray.length;
    //   const totalPage = contentArray ? contentArray.length : 0;
    const totalPage = contentArray[activeTab] ? Math.ceil(contentArray[activeTab].length / 2) : 0;


    useEffect(() => {
        setCurrentPage(1);
        if (sliderRef.current) {
            sliderRef.current.slickGoTo(0);
        }
    }, [activeTab]);
    
      const goToNext = () => {
        sliderRef.current.slickNext();
      };
    
      const goToPrev = () => {
        sliderRef.current.slickPrev();
      };
    
    return (
        <div className='explore-top-attraction-section'>
        {loading ? (
            <div></div> 
            ) : (
            <>
                <div className='explore-top-attraction-section-heading'>
                    <h1>Top Attraction</h1>
                    <div className="about-mandwa-heading-bottam-line">
                        <div className="section bottam-line-1"></div>
                        <div className="section bottam-line-2"></div>
                        <div className="section bottam-line-3"></div>
                    </div>
                </div>

                <div className="explore-subtabs-container">
                    <div className="explore-subtabs-tabs">
                        {tabTitles.map((title, index) => (
                            <div key={index} onClick={() => setActiveTab(index)} className={`tab ${activeTab === index ? 'active' : ''}`}>
                                <h1>{title}</h1>
                                <div className={`tab-underline ${activeTab === index ? 'active-underline' : ''}`} />
                            </div>
                        ))}
                    </div>

                    <div className='explore-top-desktop-view'>
                        <div className="explore-subtabs-content">
                            <div className='comman-content-top-attration-main-div'>
                                {/* {contentArray[activeTab].map((item, index) => ( */}
                                {contentArray[activeTab] && contentArray[activeTab].map((item, index) => (
                                    <div key={index} className='top-attration-main-div-content' onClick={() => handleItemClick(item)} >
                                        <div className='content-img'>
                                            {item && item.hero_image ? (
                                                <img  src={`${M2MApiUrl}${item.hero_image.replace("public/", "storage/")}`}  alt={item.title} />
                                            ) : (
                                                <img src="/assets/img/explore-page/explore-comman-content-1.png" alt={item.title} />
                                            )}
                                        </div>
                                        <div className='content-info'>
                                            <div className='content-info-div-1'>
                                                <h1>{item.title}</h1>
                                                {/* {item.tags && item.tags.length > 0 && (
                                                    <div className='content-info-div-1-logo'>
                                                        {activeTab === 1 ? (
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="17.471" height="20.862" viewBox="0 0 17.471 20.862">
                                                                <g id="_01_align_center" transform="translate(-40.725 -0.198)">
                                                                    <path id="Path_190680" data-name="Path 190680" d="M49.46,21.06l-.606-.519c-.835-.7-8.129-7-8.129-11.607a8.735,8.735,0,1,1,17.471,0c0,4.608-7.294,10.907-8.126,11.61Zm0-18.973a6.854,6.854,0,0,0-6.847,6.847c0,2.895,4.488,7.519,6.847,9.636,2.359-2.117,6.846-6.744,6.846-9.636A6.854,6.854,0,0,0,49.46,2.087Z" fill="#767c8d"/>
                                                                    <path id="Path_190681" data-name="Path 190681" d="M173.589,136.524a3.463,3.463,0,1,1,3.463-3.463A3.463,3.463,0,0,1,173.589,136.524Zm0-5.194a1.731,1.731,0,1,0,1.731,1.731A1.731,1.731,0,0,0,173.589,131.33Z" transform="translate(-124.128 -124.128)" fill="#767c8d"/>
                                                                </g>
                                                            </svg>
                                                        ):(
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="20.227" height="20.246" viewBox="0 0 20.227 20.246">
                                                            <path id="food" d="M17.148.639A2.269,2.269,0,0,0,14.7.175a2.025,2.025,0,0,0-1.2,1.89V18.981a1.265,1.265,0,0,0,2.531,0V16.886C21.186,12.978,21.63,4.939,17.148.639ZM16.028,13.281V3.158C18.13,5.6,18.316,10.732,16.028,13.281ZM10.967,1.265V6.327a5.488,5.488,0,0,1-4.218,5.33v7.324a1.265,1.265,0,1,1-2.531,0V11.657A5.488,5.488,0,0,1,0,6.327V1.265a1.265,1.265,0,1,1,2.531,0V6.327A2.953,2.953,0,0,0,4.218,8.983V1.265a1.265,1.265,0,0,1,2.531,0V8.983A2.953,2.953,0,0,0,8.436,6.327V1.265a1.265,1.265,0,1,1,2.531,0Z" transform="translate(0)" fill="#767c8d"/>
                                                            </svg>
                                                        )}
                                                        {item.tags.map((tag, index) => (
                                                            <p key={index} className='tag-item'>{tag.tag}</p> // Replace 'name' with the actual property
                                                        ))}
                                                    </div>
                                                )} */}
                                            </div>
                                            <div className='content-info-div-2'>
                                                {item.price && tabTitles[activeTab] !== "Sightseeing" && (
                                                    <h1>
                                                        <span className='price-amount'>₹ {item.price}</span>
                                                        <span className='price-text'>{item.price_text}</span>
                                                    </h1>
                                                )}
                                                {item.rating > 0 && (
                                                    <div className="content-info-div-2-logo">
                                                        <p>{item.rating}</p>
                                                        <div className='rating-star-svg'>
                                                            {Array.from({ length: 5 }, (el, index) => {
                                                                if (index < Math.floor(item.rating)) {
                                                                    // Full 
                                                                    return (
                                                                    <svg key={index} xmlns="http://www.w3.org/2000/svg" width="23.987" height="23.231" viewBox="0 0 23.987 23.231">
                                                                        <path id="Star" d="M1.327,12.4,4.887,15,3.535,19.187a3.228,3.228,0,0,0,4.984,3.594L12,20.219l3.482,2.559a3.227,3.227,0,0,0,4.983-3.591L19.113,15l3.56-2.6a3.227,3.227,0,0,0-1.9-5.832H16.4L15.073,2.432a3.227,3.227,0,0,0-6.146,0L7.6,6.568H3.231a3.227,3.227,0,0,0-1.9,5.832Z" transform="translate(-0.008 -0.19)" fill="#eeda55"/>
                                                                    </svg>
                                                                    );
                                                                } else if (index === Math.floor(item.rating) && item.rating % 1 !== 0) {
                                                                    // Half 
                                                                    return (
                                                                        // <svg key={index} xmlns="http://www.w3.org/2000/svg" width="23.987" height="23.231" viewBox="0 0 23.987 23.231">
                                                                        //     <defs>
                                                                        //         <linearGradient id="halfStarGradient" x1="0%" y1="0%" x2="100%" y2="100%">
                                                                        //             <stop offset="50%" stopColor="#eeda55" />
                                                                        //             <stop offset="50%" stopColor="#767c8d" />
                                                                        //         </linearGradient>
                                                                        //     </defs>
                                                                        //     <path id="Star" d="M1.327,12.4,4.887,15,3.535,19.187a3.228,3.228,0,0,0,4.984,3.594L12,20.219l3.482,2.559a3.227,3.227,0,0,0,4.983-3.591L19.113,15l3.56-2.6a3.227,3.227,0,0,0-1.9-5.832H16.4L15.073,2.432a3.227,3.227,0,0,0-6.146,0L7.6,6.568H3.231a3.227,3.227,0,0,0-1.9,5.832Z" transform="translate(-0.008 -0.19)" fill="url(#halfStarGradient)"/>
                                                                        // </svg>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="11.992" height="23.231" viewBox="0 0 11.992 23.231">
                                                                          <path id="Star" d="M1.327,12.4,4.887,15,3.535,19.187a3.228,3.228,0,0,0,4.984,3.594L12,20.219V.19A3.227,3.227,0,0,0,8.927,2.432L7.6,6.568H3.231a3.227,3.227,0,0,0-1.9,5.832Z" transform="translate(-0.008 -0.19)" fill="#eeda55"/>
                                                                        </svg>

                                                                    );
                                                                } else {
                                                                    // Empty 
                                                                    return (
                                                                        <svg key={index} xmlns="http://www.w3.org/2000/svg" width="23.987" height="23.231" viewBox="0 0 23.987 23.231">
                                                                            <path id="Star" d="M1.327,12.4,4.887,15,3.535,19.187a3.228,3.228,0,0,0,4.984,3.594L12,20.219l3.482,2.559a3.227,3.227,0,0,0,4.983-3.591L19.113,15l3.56-2.6a3.227,3.227,0,0,0-1.9-5.832H16.4L15.073,2.432a3.227,3.227,0,0,0-6.146,0L7.6,6.568H3.231a3.227,3.227,0,0,0-1.9,5.832Z" transform="translate(-0.008 -0.19)" fill="#767c8d"  opacity="0.5" />
                                                                        </svg>
                                                                    );
                                                                }
                                                            })}
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>

                    <div className='explore-top-mobile-view'>
                        <Slider ref={sliderRef} {...settings}>
                                    {/* {contentArray[activeTab].reduce((resultArray, item, index, array) => { */}
                                    {contentArray[activeTab] && contentArray[activeTab].reduce((resultArray, item, index, array) => {
                                        if (index % 2 === 0) {
                                            resultArray.push(array.slice(index, index + 2));
                                        }
                                        return resultArray;
                                    }, []).map((pair, index) => (
                                        <div key={index} className='slide'>
                                            {pair.map((item, subIndex) => (
                                                <div key={subIndex} className='top-attration-main-div-content' onClick={() => handleItemClick(item)} >
                                                    <div className='content-img'>
                                                        {item && item.hero_image ? (
                                                            <img  src={`${M2MApiUrl}${item.hero_image.replace("public/", "storage/")}`}  alt={item.title} />
                                                        ) : (
                                                            <img src="/assets/img/explore-page/explore-comman-content-1.png" alt={item.title} />
                                                        )}
                                                    </div>
                                                    <div className='content-info'>
                                                        <div className='content-info-div-1'>
                                                            <h1>{item.title}</h1>
                                                            {/* {item.tags && item.tags.length > 0 && (
                                                                <div className='content-info-div-1-logo'>
                                                                    {activeTab === 1 ? (
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="17.471" height="20.862" viewBox="0 0 17.471 20.862">
                                                                            <g id="_01_align_center" transform="translate(-40.725 -0.198)">
                                                                                <path id="Path_190680" data-name="Path 190680" d="M49.46,21.06l-.606-.519c-.835-.7-8.129-7-8.129-11.607a8.735,8.735,0,1,1,17.471,0c0,4.608-7.294,10.907-8.126,11.61Zm0-18.973a6.854,6.854,0,0,0-6.847,6.847c0,2.895,4.488,7.519,6.847,9.636,2.359-2.117,6.846-6.744,6.846-9.636A6.854,6.854,0,0,0,49.46,2.087Z" fill="#767c8d"/>
                                                                                <path id="Path_190681" data-name="Path 190681" d="M173.589,136.524a3.463,3.463,0,1,1,3.463-3.463A3.463,3.463,0,0,1,173.589,136.524Zm0-5.194a1.731,1.731,0,1,0,1.731,1.731A1.731,1.731,0,0,0,173.589,131.33Z" transform="translate(-124.128 -124.128)" fill="#767c8d"/>
                                                                            </g>
                                                                        </svg>
                                                                    ):(
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="20.227" height="20.246" viewBox="0 0 20.227 20.246">
                                                                        <path id="food" d="M17.148.639A2.269,2.269,0,0,0,14.7.175a2.025,2.025,0,0,0-1.2,1.89V18.981a1.265,1.265,0,0,0,2.531,0V16.886C21.186,12.978,21.63,4.939,17.148.639ZM16.028,13.281V3.158C18.13,5.6,18.316,10.732,16.028,13.281ZM10.967,1.265V6.327a5.488,5.488,0,0,1-4.218,5.33v7.324a1.265,1.265,0,1,1-2.531,0V11.657A5.488,5.488,0,0,1,0,6.327V1.265a1.265,1.265,0,1,1,2.531,0V6.327A2.953,2.953,0,0,0,4.218,8.983V1.265a1.265,1.265,0,0,1,2.531,0V8.983A2.953,2.953,0,0,0,8.436,6.327V1.265a1.265,1.265,0,1,1,2.531,0Z" transform="translate(0)" fill="#767c8d"/>
                                                                        </svg>
                                                                    )}
                                                                    {item.tags.map((tag, index) => (
                                                                        <p key={index} className='tag-item'>{tag.tag}</p> 
                                                                    ))}
                                                                </div>
                                                            )} */}
                                                        </div>
                                                        <div className='content-info-div-2'>
                                                            {item.price > 0 && (
                                                                <h1>
                                                                    <span className='price-amount'>₹ {item.price}</span>
                                                                    <span className='price-text'>{item.price_text}</span>
                                                                </h1>
                                                            )}
                                                            {item.rating > 0 && (
                                                                <div className="content-info-div-2-logo">
                                                                    <h4 className='rating-p-tag'>{item.rating}</h4>
                                                                    <div className='rating-star-svg'>
                                                                    {Array.from({ length: 5 }, (el, index) => {
                                                                        const rating = parseFloat(item.rating); 
                                                                        if (index < Math.floor(rating)) {
                                                                            // Full Star
                                                                            return (
                                                                                <svg key={index} xmlns="http://www.w3.org/2000/svg" width="23.987" height="23.231" viewBox="0 0 23.987 23.231">
                                                                                    <path d="M1.327,12.4,4.887,15,3.535,19.187a3.228,3.228,0,0,0,4.984,3.594L12,20.219l3.482,2.559a3.227,3.227,0,0,0,4.983-3.591L19.113,15l3.56-2.6a3.227,3.227,0,0,0-1.9-5.832H16.4L15.073,2.432a3.227,3.227,0,0,0-6.146,0L7.6,6.568H3.231a3.227,3.227,0,0,0-1.9,5.832Z" fill="#eeda55"/>
                                                                                </svg>
                                                                            );
                                                                        } else if (index === Math.floor(rating) && rating % 1 !== 0) {
                                                                            // Half Star with Unique Gradient ID
                                                                            const uniqueId = `halfStarGradient-${activeTab}-${index}`; 
                                                                            return (
                                                                                <svg key={index} xmlns="http://www.w3.org/2000/svg" width="11.992" height="23.231" viewBox="0 0 11.992 23.231">
                                                                                    <path id="Star" d="M1.327,12.4,4.887,15,3.535,19.187a3.228,3.228,0,0,0,4.984,3.594L12,20.219V.19A3.227,3.227,0,0,0,8.927,2.432L7.6,6.568H3.231a3.227,3.227,0,0,0-1.9,5.832Z" transform="translate(-0.008 -0.19)" fill="#eeda55"/>
                                                                                </svg>
                                                                            );
                                                                        } else {
                                                                            // Empty Star
                                                                            return (
                                                                                <svg key={index} xmlns="http://www.w3.org/2000/svg" width="23.987" height="23.231" viewBox="0 0 23.987 23.231">
                                                                                    <path d="M1.327,12.4,4.887,15,3.535,19.187a3.228,3.228,0,0,0,4.984,3.594L12,20.219l3.482,2.559a3.227,3.227,0,0,0,4.983-3.591L19.113,15l3.56-2.6a3.227,3.227,0,0,0-1.9-5.832H16.4L15.073,2.432a3.227,3.227,0,0,0-6.146,0L7.6,6.568H3.231a3.227,3.227,0,0,0-1.9,5.832Z" fill="#767c8d" opacity="0.5" />
                                                                                </svg>
                                                                            );
                                                                        }
                                                                    })} 
                                                                    </div>
                                                                </div>
                                                            )}

                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    ))}
                        </Slider>

                        <div className="pagination_Explore_about">
                            <div className={`prev_button_eight_about ${ currentPage === 1 ? "disabled" : "" }`} onClick={goToPrev}>
                                <img src="/assets/img/Partners/arrow.png" alt="prev" />
                            </div>

                            <div className="page_indicator_about">
                               {String(currentPage).padStart(2, '0')}/{String(totalPage).padStart(2, '0')}
                            </div>

                            <div className={`next_button_eight_about ${currentPage === totalPage ? "disabled" : ""}`}onClick={goToNext}>
                                <img src="/assets/img/Partners/arrow.png" alt="next" />
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )}
        </div>
    );
};

export default Top_Attration;
