import React, { useState, useEffect } from 'react';
import "./ExplorePage.css"
import MainNavbar from '../../Components/Navbar/MainNavbar';
import FotterAll from '../../Components/Footer/FotterAll';
import Explore_slider from '../../Components/Explore_Components/Explore_slider';
import Explore_Tabs_Section from '../../Components/Explore_Components/Explore_Tabs_Section';
import Top_Attration from '../../Components/Explore_Components/Top_Attration';

// import Lottie from 'lottie-react';
// import animationData from './bots-animtion.json'; 
// import mobileAnimation from './boat-animation-mobile-1.json';

const ExplorePage = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [showNav, setShowNav] = useState('true');


  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const show_nav = params.get('show_nav');
    if(show_nav)
      setShowNav(show_nav); 
}, []); 



    useEffect(() => {
      const handleResize = () => {
        setIsMobile(window.innerWidth <= 768);
      };
  
      handleResize();
  
      window.addEventListener('resize', handleResize);
  
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);

    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);
  return (
    <div className='explore-main-container'>
     <div className="navbarContainer-sticky Main-navbar-border">
            {showNav == 'true' ? <MainNavbar isHomepage={false} /> : null}
        </div>
      <div className="Schedule_Section">
        <div className="Schedule_Section_Heading">
          <h1>Explore Mandwa Port</h1>
          <p></p>
        </div>
        <div className="Schedule_WhiteLinearGradint"></div>
      </div>
      <div className="SchedulePageMainContiner">
         <Explore_slider />
      </div>
      <div className="explore-main-tabs-section">
         <Explore_Tabs_Section />
      </div>
      <div className="top-attration-section">
         <Top_Attration />
      </div>

      {/* <Lottie animationData={animationData} loop={true} /> */}
      {/* <div className='book-your-experience-container'>
           <div className='explore-top-attraction-section-heading'>
                <h1>Book Your Experience</h1>
                <div className="about-mandwa-heading-bottam-line-TAB">
                    <div className="section bottam-line-1"></div>
                    <div className="section bottam-line-2"></div>
                    <div className="section bottam-line-3"></div>
                </div>
           </div>

           <div className='book-your-experience-content'>
               <Lottie animationData={isMobile ? mobileAnimation : animationData} loop={true} />
                <div className='contetn-info-experience'>
                  <h1>Lorem Ipsum Dolor Sit Amet,Consectetur Adipiscing Elit,</h1>
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam hendrerit nisi sed sollicitudin pellentesque. Nunc posuere purus rhoncus pulvinar</p>
                  <button>Know More</button>
                </div>
           </div>
      </div> */}

      <FotterAll />
    </div>
  )
}

export default ExplorePage