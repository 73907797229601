import React, { useEffect, useState } from 'react';
import "./CalenderLine.css"; 
import moment from 'moment';
import { months } from 'moment/moment';

const CalenderLine = ({ journeyType }) => {
  const [dates, setDates] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [localData, setLocalData] = useState(null); 

  useEffect(() => {
    const getDataFormLocal = () => {
      const storedData = localStorage.getItem("formData");
      const avilableDatesData = localStorage.getItem("avilableDates");
        // console.log("avilableDatesData calnder componet : ", avilableDatesData)

      // For selling Time
      if (storedData) {
        const parsedData = JSON.parse(storedData);
        setLocalData(parsedData);
      }
    
      //for avilabel dates
      if (avilableDatesData) {
        const parsedAvilableDatesData = JSON.parse(avilableDatesData);

        const uniqueDates = [...new Set(parsedAvilableDatesData)];
  
        const dateObjects = uniqueDates.map(date => moment(date).toDate());
  
        setDates(dateObjects);
        // console.log("Date Objects: ", dateObjects);
      }
    };
    getDataFormLocal();
  }, []);

  useEffect(() => {
    if (localData) {
      const selectedDateKey = journeyType === 'onward' ? 'selectedDateNew1' : 'selecteRetdDateNew1';
      const selectedDateFromAsyncData = moment.utc(localData[journeyType === 'onward' ? 'onwardJourney' : 'returnJourney'][selectedDateKey], 'MM/DD/YYYY', true).startOf('day');

      if (selectedDateFromAsyncData.isValid()) {
        const next5Days = [];
        const availableDates = new Set(dates.map(date => moment(date).format("MM/DD/YYYY")));

        for (let i = -2; i <= 2; i++) {
          const newDate = selectedDateFromAsyncData.clone().add(i, 'days').toDate();

          if (availableDates.has(moment(newDate).format("MM/DD/YYYY"))) {
            next5Days.push(newDate);
          }
        }

        setDates(next5Days);
        setSelectedDate(selectedDateFromAsyncData.toDate());
      } else {
        console.error('Invalid date format:', localData.onwardJourney.selectedDateNew1);
      }
    }
  }, [localData, journeyType, dates]);


  const handleDateClick = (clickedDate) => {
    setSelectedDate(clickedDate);
    // console.log("clickdat",clickedDate)
    const formattedDate = moment.utc(clickedDate).format('MM/DD/YYYY');
    if (localData) {
      const updatedData = {
        ...localData,
        [journeyType === 'onward' ? 'onwardJourney' : 'returnJourney']: {
        ...localData[journeyType === 'onward' ? 'onwardJourney' : 'returnJourney'],
          [journeyType === 'onward' ? 'selectedDateNew1' : 'selecteRetdDateNew1']: formattedDate,

        },        // onwardJourney: {
        //   ...localData.onwardJourney,
        //   selectedDateNew1: formattedDate,
        // }
      };
      localStorage.setItem('formData', JSON.stringify(updatedData));
      window.location.reload();
    }
  };

  return (
    <div className="calendar-container">
      <div className="calendar-line">
        {dates.map((date, index) => {
          const day = date.toLocaleString('en-US', { weekday: 'short' });
          const dateOfMonth = date.getDate();
          const isToday = date.toDateString() === new Date().toDateString();
          const isSelected = date.toDateString() === selectedDate?.toDateString();

          return (
            <div
              key={index}
              className={`date-box ${isSelected ? 'selected' : ''} ${isToday ? 'today' : ''}`}
              onClick={() => handleDateClick(date)}
            >
              <span className={`date-text ${isSelected ? 'selected-text' : ''}`}>
                {dateOfMonth} {date.toLocaleString('default',{month : 'short'})}
              </span>
              <span className={`day-text ${isSelected ? 'selected-text' : ''}`}>
                 {date.toLocaleString('default', { weekday: 'long' })}
              </span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default CalenderLine;

