import React, { useEffect, useRef, useState } from "react";
import "./AboutUS.css";
import FotterAll from "../../Components/Footer/FotterAll";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import MainNavbar from "../../Components/Navbar/MainNavbar";
import Meta from '../../Components/Meta';
import StaticDataService from "../../services/staticData";

const AboutUS = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const sliderRef = useRef();
  const [showNav, setShowNav] = useState('true');
  const [seoMeta, setSeoMeta] = useState(null);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const show_nav = params.get('show_nav');
    if(show_nav)
      setShowNav(show_nav); 
}, []); 



  const data = [
    {
      image: "/assets/img/Explore/about_us_img1.jpg",
      heading: "Introducing Devika Saigal: The Woman Who Is Changing The Face Of Waterfront",
      button: "Read More",
      link: "https://womansera.com/introducing-devika-saigal-the-woman-who-is-changing-the-face-of-waterfront/"
    },
    {
      image: "/assets/img/Explore/abc1.png",
      heading: "M2M Ferries Receives a Maritime Excellence Achievers Award at the Global Maritime Summit",
      button: "Read More",
      link: "https://smartbusinesnews.com/business/m2m-ferries-receives-a-maritime-achievers-award-at-the-global-maritime-summit/"
    },
    {
      image: "/assets/img/Explore/abc4.png",
      heading: "Mandwa open for monsoon",
      button: "Read More",
      link: "https://www.mid-day.com/sunday-mid-day/article/mandwa-open-for-monsoon-23233112"
    },
    {
      image: "/assets/img/Explore/abc5.png",
      heading: "Mumbai RoRo service ferries 1.50 lac passengers in monsoon",
      button: "Read More",
      link: "https://indianexpress.com/article/cities/mumbai/mumbai-roro-service-ferries-1-50-lac-passengers-in-monsoon-8151673/"
    },
    {
      image: "/assets/img/Explore/abc3.png",
      heading: " Book Mumbai-Alibaug Ro-Ro ferry: Updated timings, tickets, experience",
      button: "Read More",
      link: "https://www.cntraveller.in/story/mumbai-to-alibaug-mandwa-ro-ro-car-ferry-tickets-price-time-bhaucha-dhakka-march-2020/?uID=cc8df018714935e45511e1dac59c176d4dbb25fe3d4667c78d4b6c42c6cdd86b&utm_campaign=newsletter&utm_source=newsletter&utm_medium=email&utm_brand=cnt&utm_mailing=CNT_Newsletter_Daily_2022-11-05&utm_term="
    },
    {
      image: "/assets/img/Explore/abc.png",
      heading: " Festive Season Demand: Travel and hospitality sectors hope for a take-off",
      button: "Read More",
      link: "https://www.forbesindia.com/article/2022-festive-demand/festive-season-demand-travel-and-hospitality-sectors-hope-for-a-takeoff/79513/1"
    },
  
  ];
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    arrows: false,
    afterChange: (currentSlide) => {
      setCurrentPage(currentSlide + 1);
    },
    responsive: [
      {
        breakpoint: 968,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          afterChange: (currentSlide) => {
            setCurrentPage(currentSlide + 1);
          },
        },
      },
    ],
  };

  // const totalPage = Math.ceil(data.length / 3);
  const totalPage = data.length; // Total number of slides


  const goToNext = () => {
    sliderRef.current.slickNext();
  };

  const goToPrev = () => {
    sliderRef.current.slickPrev();
  };

  const handleClick = (link) => {
    // window.location.href = link;
    window.open(link, '_blank');
  };

  // Read more and read less button
  const [showFullText, setShowFullText] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);

  const toggleText = () => {
    setShowFullText(!showFullText);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const fullText = `
  Experience the joy of Maharashtra’s first Ro-Pax ferry! Leave roadtrip hassles behind and sail from Mumbai to Mandwa in under 60 minutes with your vehicle. Enjoy the sea breeze, sunshine, and scenic Mumbai Harbour. We welcome up to 780 passengers and 120 vehicles, including cars, buses, bikes, and cycles. Your furry friends are invited too, with a special area for them on board. See you on the sail!`;

  const displayText = isMobile
    ? showFullText
      ? fullText
      : fullText.slice(0, 257) + "..."
    : fullText;

  // const showLoginButton = false;

  useEffect(() => {
    // Scroll to the top when the component mounts
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    
    StaticDataService.SeoMetaServices('Aboutus')
      .then((response) => {
        if (response.status !== "error") {
          console.log(response.seoMeta[0]);
          setSeoMeta(response.seoMeta[0]);
         
        } else {
          /* setError(response.message); */
        }
      })
      .catch((err) => {
        //setError(err.message);
        //setLoading(false);
      });
  }, []);


  return (
    <>
      {seoMeta ? (
      <Meta 
        title={seoMeta.title}
        description={seoMeta.description}
        keywords={seoMeta.keywords}
        site_name = {seoMeta.site_name}
        canonicalUrl = {seoMeta.canonicalUrl}
        url={seoMeta.url}
        image = {seoMeta.image}
      />
      ):null}
      <div className="navbarContainer-sticky Main-navbar-border">
            {showNav == 'true' ? <MainNavbar isHomepage={false} /> : null}
        </div>

      <div className="AboutUs_Container">
        <div className="AboutM2M_Section">
          <div className="AboutM2M_Section_header">
            <h1> About M2M Ferries</h1>
            {/* <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed</p> */}
          </div>
          <div className="AboutUs_WhiteLinearGradint"></div>
        </div>

        <div className="Making_Ferry_container">
          <div className="Making_Ferry">
            <div className="Making_Ferry_div1">
              <h1>Redefining Ferry Travel In India</h1>
              <div className="heading_aboutUs">
                <div className="section section-1_aboutUs"></div>
                <div className="section section-2_aboutUs"></div>
                <div className="section section-3_aboutUs"></div>
              </div>
            </div>
            <div className="Making_Ferry_div2">
              <p>{displayText}</p>
              {isMobile && (
                <p onClick={toggleText} id="MoreLessText">
                  {showFullText ? "Read Less" : "Read More.."}
                </p>
              )}
            </div>
          </div>
          <div className="imageMakingFerry">
            <img src="/assets/img/AboutUS/AboutBanner.png" alt="abc" />
          </div>
        </div>
        {/* key achivement start */}
        <div className="mainKsud">
          <div className="KeyAchivements_Container">
            <div className="logo_KeyAchivements">
              {/* <img className="desktop-logo-keyAchivemnt" src="/assets/img/CompOne/Vector.png" alt="m2mLogo" />
              <img className="mobile-logo-keyAchivemnt" src="/assets/img/CompOne/Vector_Right.png" alt="m2mMobileLogo"/> */}
            
            <div className="KeyAchivements_header">
              <h1>Milestones</h1>
              <div className="KeyAchivements_Heading">
                <div className="section section-1_keyAchive"></div>
                <div className="section section-2_keyAchive"></div>
                <div className="section section-3_keyAchive"></div>
              </div>
            </div>
            </div>
          </div>
          <div className="boxes_KeyAchivements">
            <div className="boxes_KeyAchivements_div1">
              {/* <h1>Breaking waves and records</h1> */}
              <div className="boxes_KeyAchivements_row1">
                {/* 1 st row  1st */}
                <div className="Row1_box1">
                  <img src="/assets/img/AboutUS/OverPeople.png" alt="OverPeople" />
                  <h5> 1.5 million passengers <br />sailed</h5>
                </div>

                {/* 1 st row  2nd */}
                <div className="Row1_box1">
                  <img src="/assets/img/AboutUS/CarImages.png" alt="CareearImage" />
                  <h5> 250k+ vehicles <br /> transported</h5>
                </div>
                {/* 1 st row 3rd */}
                <div className="Row1_box1">
                  <img src="/assets/img/AboutUS/Bike.png" alt="Bike" />
                  <h5>75k+ 2-wheelers <br /> transported</h5>
                </div>
              </div>
            </div>
            <div className="boxes_KeyAchivements_div2">
              {/* <h1>Uninterrupted connections, timeless precision</h1> */}
              <div className="boxes_KeyAchivements_row2">
                {/* 3st row 1rd => 1 st row 4th */}
                <div className="Row1_box1">
                  <img src="/assets/img/AboutUS/Promter.png" alt="Promoter" />
                  {/* <h5>women at the helm in <br /> every department</h5> */}
                  <h5> Inclusive <br /> workforce</h5>
                </div>
                {/* 2nd row 1st */}
                <div className="Row2_box2">
                  <img src="/assets/img/AboutUS/Year.png" alt="Year" />
                  <h5>Sailing all <br /> year-round</h5>
                </div>
                {/* 2nd row 2nd */}
                <div className="Row2_box2">
                  <img src="/assets/img/AboutUS/AboutWatch.png" alt="AboutWatch"/>
                  <h5>99% punctuality <br /> record</h5>
                </div>
                {/* 3 st row 3rd => 2nd row 3rd  */}
                {/* <div className="Row2_box2">
                  <img src="/assets/img/AboutUS/GrowthMandwa.png"alt="GrowthMandwa"/>
                  <h5>2k to 20k visitors daily <br /> at mandwa port </h5>
                </div> */}
              </div>
            </div>
            {/* <div className="boxes_KeyAchivements_div3">
              <div className="boxes_KeyAchivements_row3">
                <div className="Row3_box3">
                  <img src="/assets/img/AboutUS/Promter.png" alt="Promoter" />
                  <h5>Women at the helm in <br /> every department</h5>
                </div>
                <div className="Row3_box3">
                  <img src="/assets/img/AboutUS/GrowthMandwa.png"alt="GrowthMandwa"/>
                  <h5>2k to 20k visitors daily <br /> at Mandwa Port </h5>
                </div>
              </div>
            </div> */}
          </div>
        </div>
        {/* key achivement end */}

         {/* Award SEction Start */}
         <div className="AboutAwardSection">
          <div className="AwardSection_Heading">
            <h1>Achievements </h1>
            <div className="heading_aboutUs_award">
              <div className="section section-1_aboutUs_award"></div>
              <div className="section section-2_aboutUs_award"></div>
              <div className="section section-3_aboutUs_award"></div>
            </div>
          </div>
          <div className="AwardSection_Images">
            {/* <div>
              <img src="/assets/img/AboutUS/Images1.png" alt="1" />
            </div>
            <div>
              <img src="/assets/img/AboutUS/Images2.png" alt="2" />
            </div>
            <div>
              <img src="/assets/img/AboutUS/Images3.png" alt="3" />
            </div> */}
            <div>
              <img src="/assets/img/AboutUS/Images4.png" alt="achivment-1" />
            </div>
            <div>
              <img src="/assets/img/AboutUS/NewAchivement.png" alt="achivment-2" />
            </div>
          </div>
        </div>

        {/* MAndawa About us start */}
        <div className="aboutExploreMandwa">
          <div className="aboutExploreMandwa_heading">
            <h1>In The Spotlight</h1>
            <div className="OurPartners_Heading">
              <div className="section section-1_aboutUs"></div>
              <div className="section section-2_aboutUs"></div>
              <div className="section section-3_aboutUs"></div>
            </div>
          </div>
          <div className="slider_container_about">
            <Slider ref={sliderRef} {...settings}>
              {data.map((item, index) => (
                <div key={index} className="slides_about">
                  <div className="border-radius_about">
                    <img src={item.image} alt={item.heading} />
                  </div>
                  <div className="TextSlideContent">
                    <h2>{item.heading}</h2>
                    <button className="aboutusButton" onClick={() => handleClick(item.link)}>{item.button}</button>
                  </div>
                </div>
              ))}
            </Slider>
            <div className="pagination_Explore_about">
              <div className="prev_button_eight_about" onClick={goToPrev} disabled={currentPage === 1}>
                <img src="/assets/img/Partners/arrow.png" alt="dcs" />
              </div>

              <div className="page_indicator_about">
                {String(currentPage).padStart(2, '0')}/{String(totalPage).padStart(2, '0')}
              </div>
              <div
                className="next_button_eight_about"
                onClick={goToNext}
                disabled={currentPage === totalPage}
              >
                <img src="/assets/img/Partners/arrow.png" alt="dcs" />
              </div>
            </div>
          </div>
        </div>
        
        {/* Our partners */}
        <div className="OurPartners_about">
          <div className="OurPartners_about_heading">
            <div className="HeadingContet">
              <h2>Our Partners </h2>
              <div className="OurPartners_Heading">
                <div className="section section-1_aboutUs"></div>
                <div className="section section-2_aboutUs"></div>
                <div className="section section-3_aboutUs"></div>
              </div>
            </div>
          </div>

          <div className="mainLogoConiner">
            <div className="mainLogoConiner_boxs">
              <div className="mainLogoConiner_boxs1">
                <img src="/assets/img/AboutUS/OurPartenersImg1.png" alt="mmb" />
                <p>Mumbai Port Trust</p>
              </div>
              <div className="mainLogoConiner_boxs1">
                <img src="/assets/img/AboutUS/OurPartenersImg2.png" alt="mbpt"/>
                <p>Maharashtra Maritime Board</p>
              </div>{" "}
            </div>
          </div>
        </div>

      
      </div>
      {showNav == 'true' ?  <FotterAll /> : null}
    </>
  );
};

export default AboutUS;
