import React, { useEffect, useRef, useState } from "react";
import "./Explore_SubTabsPage.css";
import MainNavbar from '../../Components/Navbar/MainNavbar';
import FotterAll from '../../Components/Footer/FotterAll';
import Explore_Tabs_Gallery from '../../Components/Explore_Components/Explore_Tabs_Gallery';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Explore_Comman_slider_Tabs from "../../Components/Explore_Components/Explore_Comman_slider_Tabs";
import Explore_location_Comman from "../../Components/Explore_Components/Explore_location_Comman";
import { useNavigate } from 'react-router-dom';
import StaticDataService from "../../services/staticData";
import { useParams } from 'react-router-dom';
// import { M2MApiUrl } from "../../services/staticData";


const Explore_SubTabsPage = ({}) => {
  // console.log("explore-page-slug",slug)
  const { postName } = useParams();
  const { category } = useParams();

  // console.log("category in explor subpage: ", category)


  const navigate = useNavigate();
  const [subPageData, setSubPageData] = useState(null);

  const handleSubmitReverse = () => {
    navigate('/explore');
  };

  // console.log(postName);

  useEffect(() => {
    StaticDataService.exploreServiceSubPages(postName)
      .then((response) => {
        if (response) {
          setSubPageData(response.post); 
        } else {
          console.error("Error in response:", response);
        }
      })
      .catch((err) => {
        console.error("API Error:", err);
      });
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

// const currentPageData = subPageData?.find((item) => item.slug === postName);

const currentPageData = subPageData?.find((item) => item.slug === postName);

if (!currentPageData) {
  return <div></div>
}
// console.log("subPageData :", subPageData);
// console.log("currentPageData :", currentPageData);

// if(!currentPageData) return <div>Loading.... </div>
// const handleClick = () => {
//   // console.log("aksdh Link", subPageData[0].know_more_link);
//   const knowMoreLink = subPageData[0].know_more_link;
//     if (knowMoreLink && knowMoreLink !== '') {
//     window.location.href = knowMoreLink;
//   } else {
//     console.error("Invalid or undefined 'know_more_link'");
//   }
// };

  return (
    <div className='explore-comman-tabs-container'>
      <div className="navbarContainer-sticky Main-navbar-border">
        <MainNavbar />
      </div>
      <div className="explore-comman-tabs-heading-content">
           <div className="back-to-home-page">
                  <h1 onClick={handleSubmitReverse}>Explore Mandwa Port</h1>
                  <svg className="back-to-home-page-svg" xmlns="http://www.w3.org/2000/svg" width="12.705" height="21.229" viewBox="0 0 12.705 21.229">
                      <path id="Alt_Arrow_Down" data-name="Alt Arrow Down" d="M21.988,9l-8.494,9.084L5,9" transform="translate(-6.88 24.109) rotate(-90)" fill="none" stroke="#767c8d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3"/>
                   </svg>
                  <h3>{currentPageData.title}</h3>
            </div>
        <div className="explore-comman-tabs-heading">
           <h1>{currentPageData.title}</h1>
        </div>
        <div className="white-liner-gradient"></div>
      </div>

      <div className='explore-comman-gallery'>
         <Explore_Tabs_Gallery currentGallryData={currentPageData}/>
      </div>


      <div className='explore-comman-about-comp'>
          <div className='explore-comman-about-comp-div-1'>
          <div className='about-heding-rest-desktop'>
            <h1>About {currentPageData.title}</h1>
          <div className="about-mandwa-heading-bottam-line" style={{margin:0}}>
                      <div className="section bottam-line-1"></div>
                      <div className="section bottam-line-2"></div>
                      <div className="section bottam-line-3"></div>
              </div>
          </div>
          <div className='about-heding-rest-mobile'>
            <h1>About {currentPageData.title}</h1>
          <div className="about-mandwa-heading-bottam-line" style={{margin:"auto"}}>
                      <div className="section bottam-line-1"></div>
                      <div className="section bottam-line-2"></div>
                      <div className="section bottam-line-3"></div>
              </div>
          </div>
          </div>
          <div className="explore-comman-about-comp-div-2">
            <p dangerouslySetInnerHTML={{ __html: currentPageData.description }} />
            {/* <h5>₹ {currentPageData.price} </h5> */}
            {/* <h2>Starting cost per person</h2> */}
            {currentPageData.price > 0 && (
                <h2>{currentPageData.sub_title} {currentPageData.price}</h2>
            )}
            {/* <button className="row-button-schedule-BookNow"  type="submit" onClick={handleClick}>Know More</button> */}
            {currentPageData.menu_pdf && (
                <button className="row-button-schedule-BookNow" type="button" onClick={() => window.open(currentPageData.menu_pdf, '_blank')}>View Menu</button>
            )}
          </div>
      </div>

      {/* <div className='explore-overview-restaurent'>
          <h1>Amenities & Features</h1>
          <div className="about-mandwa-heading-bottam-line" style={{paddingBottom:'25px'}}>
                    <div className="section bottam-line-1"></div>
                    <div className="section bottam-line-2"></div>
                    <div className="section bottam-line-3"></div>
          </div>
          <div className="explore-container">
              <div className="columns">
                  <div className="column">
                      <h3>Property amenities</h3>
                      <div className="explore-row">
                          <ul>
                              <li>Swimming Pool</li>
                              <li>Kids Friendly</li>
                              <li>Pet Friendly</li>
                              <li>Air-Conditioned Rooms</li>
                              <li>Private Balcony</li>
                          </ul>
                          <ul>
                              <li>Free Parking Area</li>
                              <li>Swimming Pool</li>
                              <li>Kids Friendly</li>
                              <li>Pet Friendly</li>
                              <li>Private Balcony</li>
                          </ul>
                      </div>
                  </div>
                  <div className="column-1-rest">
                      <h3>Room amenities</h3>
                      <ul>
                          <li>Swimming Pool</li>
                          <li>Kids Friendly</li>
                          <li>Pet Friendly</li>
                          <li>Air-Conditioned Rooms</li>
                          <li>Private Balcony</li>
                      </ul>
                  </div>
              </div>
          </div>
      </div> */}

    {/* <div>
      <Explore_location_Comman category={category} currentLoactionData={currentPageData}/>
    </div> */}

    {category !== "sightseeing" ? (
      <div>
        <Explore_location_Comman category={category} currentLoactionData={currentPageData} />
      </div>
    ) : (
      <div className="loaction-full-page">
      <h1>Location</h1>
            <div className="about-mandwa-heading-bottam-line" style={{}}>
                    <div className="section bottam-line-1"></div>
                    <div className="section bottam-line-2"></div>
                    <div className="section bottam-line-3"></div>
            </div>
        {/* <Explore_location_Comman/> */}
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3776.8344936234703!2d72.87917241489708!3d18.805527787243708!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zMTjCsDQ4JzE5LjkiTiA3MsKwNTInNTIuOSJF!5e0!3m2!1sen!2sin!4v1616837987847!5m2!1sen!2sin"  allowFullScreen="" loading="lazy"></iframe>
      </div>
    )}


    <div>
      <Explore_Comman_slider_Tabs category={category} commanSliderData={currentPageData}/>
    </div>



      <FotterAll />
    </div>
  )
}

export default Explore_SubTabsPage

