import React from "react";
import "./ErrorPage.css";
import MainNavbar from "../../Components/Navbar/MainNavbar";
import FotterAll from "../../Components/Footer/FotterAll";
import {
  MdOutlineEmail,
  MdOutlineMailOutline,
  MdOutlineMarkEmailRead,
  MdOutlineWifiCalling3,
} from "react-icons/md";


const BookingFailure = ({booking_id}) => {
  const bookingId = booking_id;
  
    
  return (
    <>
      <div className="ErrorPageContent">
          <div className="ErrorPageContent_Div1">
            <div className="EmailError">
              <MdOutlineMailOutline />
              <p>support@m2mferries.com</p>
            </div>
            <div className="EmailError">
              <MdOutlineWifiCalling3 />
              <p>+ 91 8291902662</p>
            </div>
          </div>

          <div className="ErrorPage_MainContet">
            <div className="ErrorDiv1">
              <img src="/assets/img/Error/ErrorImg.png" alt="Error" />
            </div>
            <div className="ErrorDiv2">
              <h1>Sorry Your Booking Could Not Be Completed !</h1>
              {/* <p>The Payment For This Transection Has Failed</p> */}
            </div>
            <div className="ErrorDiv3">
              <p>
              The payment for this transaction has failed. <br />
              Please check your payment details and try again.<br />
              In case of any queries, you can email us at support@m2mferries.com or call at +91 8291902662.
              </p>
            </div>
            <div>              
              <a className="link-without-underline " href="/"><button className="ErrorPageButton">Try Again</button></a>
            </div>
          </div>
        </div>
    </>
  )
}

export default BookingFailure
