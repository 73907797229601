import React, { useState, useEffect, useRef } from "react";
import FotterAll from "../../Components/Footer/FotterAll";
import { Link } from "react-router-dom";
import "./ConfirmationPage.css";
import Modal from "react-modal";
import PassangerDetail from "../../Components/OnwordBooking_Comp/Confirmation/PassangerDetail";
import BookingSummary from "../../Components/OnwordBooking_Comp/Confirmation/BookingSummary";
// import moment from "moment";
import MainNavbar from "../../Components/Navbar/MainNavbar";

const ConfirmationPage = () => {
  
  const showLoginButton = false;

  const [storedData, setStoredData] = useState(null);
  const [tripTotalPrice, setTripTotalPrice] = useState(0);
  const [userPData, setUserPData] = useState([]);

  const [actionId, setActionId] = useState(0);

  const nameMap = { MUM: "Mumbai", MWA: "Mandwa" };

     // For Browser ALerts
  const [isBrowserAlerts, setIsBrowserAlerts] = useState();
  const closeBrowserModal = () => {
    setIsBrowserAlerts('');
  };


  useEffect(() => {
    if(performance.navigation.type == 2){
      window.location='/';
    }
    const dataFromLocalStorage = localStorage.getItem("formData");
    const storedData = JSON.parse(dataFromLocalStorage);
    if(storedData){
      setStoredData(storedData);
    }
    else{
      window.location='/';
    }
  }, []);
 
  useEffect(() => {
    const storedUserData = JSON.parse(localStorage.getItem("userData"));
    setUserPData(storedUserData);
  }, []);
 
  
  useEffect(() => {
    //console.log("performance : ",performance.navigation.type);
    
    const dataFromLocalStorage = localStorage.getItem("formData");
    const storedData = JSON.parse(dataFromLocalStorage);
    var onwardPassengerTotal = 0;
    var onwardVehicleTotal = 0;
    var onwardTotalPrice = 0;
    var returnPassengerTotal = 0;
    var returnVehicleTotal = 0;
    var returnTotalPrice = 0;
    var tripTotalPrice = 0;
    // console.log(storedData);
    if (
      storedData &&
      storedData.onwardJourney &&
      storedData.onwardJourney.passengersData
    ) {
      const { onwardJourney } = storedData;
      const { passengersData, SelTripData } = onwardJourney;
      const { selectedVehicles, petinfo, available } = SelTripData;

      if (passengersData) {
        var Adultcount = passengersData.Adultcount;
        var Childcount = passengersData.Childcount;
        var Petcount = passengersData.Petcount;
        console.log("Petcount : ",Petcount);
        var adultPrice = Adultcount * available.single_room_price;
        var childPrice = Childcount * 0;
        if(Petcount > 0){
          var petPrice = Petcount * petinfo.price;
        }
        else{
          var petPrice = Petcount * 0;
        }
        onwardPassengerTotal = adultPrice + childPrice + petPrice;

        if (selectedVehicles) {
          selectedVehicles.map((item) => {
            onwardVehicleTotal += Number(item.price * item.quantity);
          });
        }
      }
      //console.log(onwardVehicleTotal);
      onwardTotalPrice = onwardPassengerTotal + onwardVehicleTotal;
    }
    // console.log(onwardTotalPrice);
    if (
      storedData &&
      storedData.returnJourney &&
      storedData.returnJourney.passengersData
    ) {
      const { returnJourney } = storedData;
      const { passengersData, SelTripData } = returnJourney;
      const { selectedVehicles, petinfo, available } = SelTripData;

      if (passengersData) {
        var Adultcount = passengersData.Adultcount;
        var Childcount = passengersData.Childcount;
        var Petcount = passengersData.Petcount;

        var adultPrice = Adultcount * available.single_room_price;
        var childPrice = Childcount * 0;
        var petPrice = Petcount * petinfo.price;
        returnPassengerTotal = adultPrice + childPrice + petPrice;

        if (selectedVehicles) {
          selectedVehicles.map((item) => {
            returnVehicleTotal += Number(item.price * item.quantity);
          });
        }
      }
      returnTotalPrice = returnPassengerTotal + returnVehicleTotal;
    }

    tripTotalPrice = onwardTotalPrice + returnTotalPrice;
    setTripTotalPrice(parseFloat(tripTotalPrice));

    if(process.env.REACT_APP_ENV === "production" && tripTotalPrice > 0){    
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.innerHTML = `
        !function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)}(window, document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', '228849599503791');
        fbq('track', 'InitiateCheckout', {currency: "INR", value: ${tripTotalPrice}});
      `;
      document.body.insertBefore(script, document.body.firstChild);

      // Cleanup the script when the component unmounts
      return () => {
        document.body.removeChild(script);
      };
    }
    //console.log(tripTotalPrice);
  }, [tripTotalPrice]);

  useEffect(() => { //
//console.log(tripTotalPrice);
    
  }, []);
  if (!storedData) {
    return <div></div>;
  }

  const bookNowBtnClick = () => {
    setActionId(prevId => prevId + 1);
    /* setAction(!action); */
    /* if (!userPData) {
      setIsBrowserAlerts("Please sign in or enter your details"); 
    } else {
      
    } */
  };

  
  return (
  <>
    <div style={{ position: "relative" }}>
      <div className="ConfimrationNavbr_Sticky_breadcum">
        <div style={{ borderBottom: "1px solid rgba(33, 145, 208, 1)"}}>
        <MainNavbar isHomepage={false} />
        </div>
        <div className="Breadcum_Line_conf" style={{ overflowX: "auto" }}>
          <div className="Breadcum_Flow_conf">
            <Link to={"/"} className="link-without-underline">
              <div className="Breadcum_Flow_Home_conf">
                <h1>M2M</h1>
                <p> Home</p>
              </div>
            </Link>
            <div className="Breadcum_Flow_Logo_conf">
              <h1>&#62;</h1>
            </div>
            <Link to={"/onward-departure"} className="link-without-underline">
              <div className="Breadcum_Flow_Departure_conf">
                <h1>Departure</h1>
                <p>
                  {nameMap[storedData.onwardJourney.SelTripData.start_port]} To{" "}
                  {nameMap[storedData.onwardJourney.SelTripData.end_port]}
                </p>
              </div>
            </Link>
            {storedData.triptype === "return" && (
              <>
                <div className="Breadcum_Flow_Logo_conf">
                  <h1>&#62;</h1>
                </div>
                <Link to={"/onward-return"} className="link-without-underline">
                  <div className="Breadcum_Flow_Departure_conf">
                    <h1>Return</h1>
                    <p>
                      {nameMap[storedData.onwardJourney.SelTripData.end_port]}{" "}
                      To{" "}
                      {nameMap[storedData.onwardJourney.SelTripData.start_port]}
                    </p>
                  </div>
                </Link>
              </>
            )}
            {((storedData.onwardJourney &&
              storedData.onwardJourney.SelTripData &&
              storedData.onwardJourney.SelTripData.selectedVehicles &&
              storedData.onwardJourney.SelTripData.selectedVehicles.length >
                0) ||
              (storedData.onwardJourney &&
                storedData.onwardJourney.selectedDateNew1 &&
                storedData.onwardJourney.selectedFromAbbreviation &&
                storedData.onwardJourney.selectedToAbbreviation &&
                storedData.onwardJourney.vehicleData)) &&
              (storedData.onwardJourney.SelTripData &&
              storedData.onwardJourney.SelTripData.selectedVehicles &&
              storedData.onwardJourney.SelTripData.selectedVehicles.length >
                0 ? (
                <>
                  <div className="Breadcum_Flow_Logo_conf">
                    <h1>&#62;</h1>
                  </div>
                  <Link to={"/vehicle"} className="link-without-underline">
                    <div className="Breadcum_Flow_Departure_conf">
                      <h1>Vehicle</h1>
                      <p>Choose Vehicle</p>
                    </div>
                  </Link>
                </>
              ) : null)}

            <div className="Breadcum_Flow_Logo_conf">
              <h1>&#62;</h1>
            </div>
            <div className="Breadcum_Flow_Departure_conf" id="currentSlide">
              <h1>Summary</h1>
              <p>Confirm Booking</p>
            </div>
          </div>
        </div>
      </div>
      <div className="ConfirmationPage_container">
        {/* main */}
        <div className="confirmation_summary_boxs">
          {/* Right side  */}
          <PassangerDetail action={actionId} />  {/* bookNowBtnClick={bookNowBtnClick}  */}

          {/* left side */}
          <BookingSummary />
        </div>
      </div>

      <FotterAll />

      {/* TotalFair FlowWidget */}
      
      {/* <div className="TotalFair_ConfirmationPage">
        <div className="TotalFair_ConfirmationPage_SectionOne">
          <h1>Total Fair</h1>
          <p>₹{tripTotalPrice ? tripTotalPrice.toFixed(2) : 0}</p>
        </div>
        <div >
          <button className="TotalFair_ConfirmationPage_SectionTwo" onClick={bookNowBtnClick}>Book Now</button>
        </div>
      </div> */}
      {userPData ? (
        <div className="TotalFair_ConfirmationPage userLoggedIn">
          <div className="TotalFair_ConfirmationPage_SectionOne">
            <h1>Total Fare</h1>
            <p>₹{tripTotalPrice ? tripTotalPrice.toFixed(2) : 0}</p>
          </div>
          <div>
            <button className="TotalFair_ConfirmationPage_SectionTwo loggedIn" onClick={bookNowBtnClick}>Book Now</button>
          </div>
        </div>
      ) : (
        <div className="TotalFair_ConfirmationPage">
          <div className="TotalFair_ConfirmationPage_SectionOne">
            <h1>Total Fare</h1>
            <p>₹{tripTotalPrice ? tripTotalPrice.toFixed(2) : 0}</p>
          </div>
          <div>
            <button className="TotalFair_ConfirmationPage_SectionTwo" onClick={bookNowBtnClick}>Book Now</button>
          </div>
        </div>
      )}


      <Modal isOpen={isBrowserAlerts}  contentLabel="Example Modal" className="custom-modal-alert">
           <p>{isBrowserAlerts}</p>
           <div className="ModalButton"><button onClick={closeBrowserModal}>Ok</button></div>
        </Modal> 
    </div>
  </>
  );
};

export default ConfirmationPage;
