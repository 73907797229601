import React from 'react';
import "./Explore_location_Comman.css"

const Explore_location_Comman = ({currentLoactionData}) => {

    const handleClick = () => {
        // navigate(currentLoactionData.know_more_link); 

        if(currentLoactionData && currentLoactionData.location_link){
            window.location.href = currentLoactionData.location_link; 
        }else{
            console.log('no valid link avilable')
        }
    };

  return (
    <div className='explore-location-contact'>
        <h1>Location And Contact</h1>
        <div className="about-mandwa-heading-bottam-line" style={{}}>
            <div className="section bottam-line-1"></div>
            <div className="section bottam-line-2"></div>
            <div className="section bottam-line-3"></div>
        </div>

        <div className='explore-location-contact-container'>
        <div className='explore-location-contact-container-div-1'>
            <h1>{currentLoactionData.title}</h1>
            {currentLoactionData.location_mobile ? (
            <p dangerouslySetInnerHTML={{__html:currentLoactionData.location_address}}/>
            ):(null)}

            <div className='contact-us-section'>
                <h2>Contact Deatils</h2>
                {currentLoactionData.location_mobile ? (
                    <div className='contact-us-section-div'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="18.137" viewBox="0 0 18.106 18.137">
                            <path id="Icon_feather-phone-call" data-name="Icon feather-phone-call" d="M13.078,4.564A3.83,3.83,0,0,1,16.1,7.59M13.078,1.5a6.894,6.894,0,0,1,6.09,6.082M18.4,13.694v2.3a1.532,1.532,0,0,1-1.67,1.532,15.159,15.159,0,0,1-6.61-2.352,14.937,14.937,0,0,1-4.6-4.6A15.159,15.159,0,0,1,3.174,3.936,1.532,1.532,0,0,1,4.7,2.266H7A1.532,1.532,0,0,1,8.528,3.583a9.835,9.835,0,0,0,.536,2.152A1.532,1.532,0,0,1,8.72,7.352l-.973.973a12.256,12.256,0,0,0,4.6,4.6l.973-.973a1.532,1.532,0,0,1,1.616-.345,9.835,9.835,0,0,0,2.152.536A1.532,1.532,0,0,1,18.4,13.694Z" transform="translate(-2.167 -0.396)" fill="none" stroke="#01181f" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
                        </svg>
                        {/* <p>{currentLoactionData.location_mobile}</p> */}
                        <a href={`tel:${currentLoactionData.location_mobile}`}>
                            <p>{currentLoactionData.location_mobile}</p>
                        </a>
                    </div>
                    ):(
                    null
                    )
                }
                <div className='contact-us-section-div'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="12.8" viewBox="0 0 16 12.8">
                    <path id="Icon_material-mail-outline" data-name="Icon material-mail-outline" d="M17.4,6H4.6A1.6,1.6,0,0,0,3.008,7.6L3,17.2a1.6,1.6,0,0,0,1.6,1.6H17.4A1.6,1.6,0,0,0,19,17.2V7.6A1.6,1.6,0,0,0,17.4,6Zm0,11.2H4.6v-8l6.4,4,6.4-4ZM11,11.6l-6.4-4H17.4Z" transform="translate(-3 -6)" fill="#01181f"/>
                    </svg>
                    <a href="mailto:support@m2mferries.com">
                        <p>support@m2mferries.com</p>
                     </a>
                </div>
            </div>
            
            {/* <button className="row-button-schedule-BookNow"  type="submit" onClick={handleClick}>Know More</button> */}
            <div className='socaial-media-icon-location'>
                        {currentLoactionData.instagram_url && (
                            <a  href={currentLoactionData.instagram_url}  target="_blank"  rel="noopener noreferrer">
                                <img src="/assets/images/instagram.png" alt="instagram" />
                           </a>
                        )}
                        {currentLoactionData.facebook_url && (
                            <a  href={currentLoactionData.facebook_url}  target="_blank"  rel="noopener noreferrer">
                                <img src="/assets/images/facebook.png" alt="facebook" />
                            </a>
                        )}
                        {currentLoactionData.twitter_url && (
                            <a href={currentLoactionData.twitter_url} target="_blank" rel="noopener noreferrer">
                                <img src="/assets/images/twitter.png" alt="twitter" />
                            </a>
                        )}
                        {currentLoactionData.linkdin_url && (
                            <a  href={currentLoactionData.linkdin_url}  target="_blank"  rel="noopener noreferrer">
                                 <img src="/assets/images/linkedin.png" alt="linkdin" />
                            </a>
                        )}
            </div>
        </div>
        <div className='explore-location-contact-container-div-2'>
            <iframe src={currentLoactionData.location_link}  allowFullScreen="" loading="lazy"></iframe>
        </div>

        </div>
    </div>
  )
}

export default Explore_location_Comman